import { animated, useSpring } from '@react-spring/web'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'

import { useReportError } from '/machinery/ReportError'

import { DocumentCardLightBlue, ImageCard, VideoCard, QuoteCard, EventCard } from '/features/pageOnly/LifeAtKLM/ContentCards'

import styles from './ContentCollection.css'

export function ContentCollection({ items, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      {items.map((item, i) => (
        <AnimatedCard
          key={i}
          sinePosition={getSinePositionForCard(item, items, 0.7)}
          {...{ item }}
        />
      ))}
    </div>
  )
}

function getSinePositionForCard(item, items, gridGap) {
  const index = items.indexOf(item)
  const phaseShift = 0.2 * Math.PI * index
  const frequencyShift = 1
  const amplitudeShift = 1
  const sinePosition = Math.sin(index * frequencyShift + phaseShift) * amplitudeShift

  return sinePosition * gridGap
}

function AnimatedCard({ item, sinePosition }) {
  const { ref: viewportRef, wasInViewport } = useWasInViewport()

  const gridLayout = getClassNameByType({ item })

  const style = useSpring({
    y: wasInViewport ? sinePosition * 20 : 50,
    x: sinePosition * 30,
    config: { tension: 200, friction: 90 },
  })

  return (
    <animated.div ref={viewportRef} className={cx(styles.componentAnimatedCard, gridLayout)} {...{ style }}>
      <Card {...{ item }} />
    </animated.div>
  )
}

function Card({ item }) {
  const reportError = useReportError()

  switch (item._type) {
    case 'article': return <DocumentCardLightBlue {...{ item }} />
    case 'project': return <DocumentCardLightBlue {...{ item }} />
    case 'imageCard': return <ImageCard {...{ item }} />
    case 'videoCard': return <VideoCard {...{ item }} />
    case 'quoteCard': return <QuoteCard {...{ item }} />
    case 'event': return <EventCard {...{ item }} />
    default: {
      reportError(new Error(`Unknown card type '${item._type}'`))
      return null
    }
  }
}

function getClassNameByType({ item }) {
  switch (item._type) {
    case 'article': return styles.documentCardLayout
    case 'project': return styles.documentCardLayout
    case 'imageCard': return styles.imageCardLayout
    case 'videoCard': return styles.videoCardLayout
    case 'quoteCard': return styles.quoteCardLayout
    case 'event': return styles.eventCardLayout
    default: return null
  }
}
